import axios from "axios";
// delete axios.defaults.headers.common["x-access-token"];
axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Token'] = process.env.REACT_APP_Api_Token;
const API_URL = process.env.REACT_APP_API_URL;

// Get Blogs
const GetBlogsOld = (cancel) => {
    return axios.get(`${API_URL}Total.json`, {
        cancelToken: cancel
    })
}

// Get Blogs
const GetBlogs = (data,cancel) => {
    return axios.post(`${API_URL}Blog_data`, data, {
        cancelToken: cancel
    })
}

export default {
    GetBlogs
}