import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import axios from 'axios';
import Header from '../Components/Header';
import BlogList from '../Components/BlogList';
import Footer from '../Components/Footer';
import Banner from '../Components/Banner';
import SiteBarSearch from '../Components/SiteBarSearch';
import Categories from '../Components/Categories';
import PopularPost from '../Components/PopularPost';
import NoSearchFound from '../Components/NoSearchFound';
import { BlogContext } from '../App';
import ApiService from '../Services/Api.service';
import { Fade, Flip } from 'react-reveal';



const Home = () => {
    const { state, dispatch } = useContext(BlogContext);
    const [AllBlogData, setAllBlogData] = useState([]);
    const [TotalCounts, setTotalCounts] = useState(0);
    const [Article_count, setArticle_count] = useState(0);
    const [Earnings_count, setEarnings_count] = useState(0);
    const [News_count, setNews_count] = useState(0);
    const [page_num, setpage_num] = useState(-1);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [FilterItm, setFilterItm] = useState(0);
    const [SearchQuery, setSearchQuery] = useState(null);

    let searchKeys = ["Citation", "Content", "Heading", "Industry", "Name", "Quarter", "Sector", "Ticker", "Year", "Sentiment"];

    const getTotalCount = (id) => {
        let c = 0;
        switch (+id) {
            case 1:
                c = News_count;
                break;
            case 2:
                c = Article_count;
                break;
            case 3:
                c = Earnings_count;
                break;
            default:
                c = TotalCounts;
                break;
        }
        return c;
    }
    let pageCount = 0;
    const getcurrentItems = () => {
        let fdata = []
        let data = [];
        // console.log(FilterItm)
        switch (true) {
            case  FilterItm !== 0 && !SearchQuery:
                data = AllBlogData.filter((itm) => +itm.Type === +FilterItm).slice(itemOffset, endOffset)
                pageCount = Math.ceil(getTotalCount(FilterItm) / itemsPerPage)
                break;

            case  FilterItm === 0 && (SearchQuery !== null && SearchQuery !== ""):
                // console.log(FilterItm,SearchQuery,2)
                fdata = AllBlogData.filter((itm) =>
                searchKeys.some((key) => itm[key] !== undefined ? itm[key]?.toLowerCase()
                    .includes(SearchQuery.toLowerCase()) : null));
                data =  fdata.slice(itemOffset, endOffset);
                    console.log(itemOffset, endOffset)
                    pageCount = Math.ceil(fdata.length / itemsPerPage)
                break;

            case  FilterItm !== 0 && (SearchQuery !== null && SearchQuery !== ""):
                // console.log(FilterItm,SearchQuery,3)
                fdata = AllBlogData.filter((itm) =>
                searchKeys.some((key) => +itm.Type === +FilterItm && itm[key] !== undefined ? itm[key]?.toLowerCase()
                    .includes(SearchQuery.toLowerCase()) : null ));
                data = fdata.slice(itemOffset, endOffset);
                    pageCount = Math.ceil(fdata.length / itemsPerPage)
                break;

            default:
                // console.log(FilterItm,SearchQuery,4)
                data = AllBlogData.slice(itemOffset, endOffset)
                pageCount = Math.ceil(getTotalCount(FilterItm) / itemsPerPage)
                break;

        }
        return data;
    }

    const endOffset = itemOffset + itemsPerPage;
    let currentItems = getcurrentItems();

    const sourceTocken = axios.CancelToken.source();

    const getBlogData = () => {
        if (!AllBlogData.length) {
            let fdata = {
                "page_num": page_num
            }
            ApiService.GetBlogs(fdata, sourceTocken.token)
                .then(res => {
                    const data = [...res.data?.Article, ...res.data?.Earnings_Call, ...res.data?.News];
                    dispatch({ type: "BLOGDATA", payload: data });
                    setAllBlogData(data);
                    setArticle_count(res.data?.Article_count)
                    setEarnings_count(res.data?.Earnings_count)
                    setNews_count(res.data?.News_count)
                    setTotalCounts(res.data?.Article_count + res.data?.Earnings_count + res.data?.News_count)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        getBlogData()
    }, [AllBlogData]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % getTotalCount(FilterItm);
        setItemOffset(newOffset);
        
        // setpage_num(newOffset);

        // let fdata = {
        //     "page_num": newOffset
        // }
        // ApiService.GetBlogs(fdata, sourceTocken.token)
        //     .then(res => {
        //         const data = [...res.data?.Article, ...res.data?.Earnings_Call, ...res.data?.News];
        //         dispatch({ type: "BLOGDATA", payload: data });
        //         setAllBlogData(valus => [...valus, data]);
        //         setArticle_count(res.data?.Article_count)
        //         setEarnings_count(res.data?.Earnings_count)
        //         setNews_count(res.data?.News_count)
        //         setTotalCounts(res.data?.Article_count + res.data?.Earnings_count + res.data?.News_count)
        //     })
        //     .catch(err => {
        //         console.log(err)
        //     })

    };

    // Invoke when user click to request another page.
    const handleFIlter = (id) => {
        setItemOffset(0)
        setFilterItm(id);
    };

    // Invoke when user click to request another page.
    const handleSearch = (e) => {
        e.preventDefault()
        setItemOffset(0)
        setSearchQuery(e.currentTarget.elements.search_input.value)
    };

    return (
        <>
            <Header />
            <Banner />
            <section className="site-section py-lg">
                <Container className='bg-white'>
                    <Row className='blog-entries element-animate mb-5'>
                        <Col md={8} lg={8}>
                            {/* <NoSearchFound /> */}
                            <BlogList AllBlogData={currentItems} />
                            <div className='text-center blog-pagination'>
                                <ReactPaginate className='pagination'
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={itemsPerPage}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                />
                            </div>


                        </Col>
                        <Col md={4} lg={4}>
                            <Fade right>
                                <SiteBarSearch handleSearch={handleSearch} />
                            </Fade>
                            <Fade right>
                                <Categories AllBlogData={AllBlogData} TotalCounts={TotalCounts} Article_count={Article_count} Earnings_count={Earnings_count} News_count={News_count} FilterItm={handleFIlter} currentFilter={FilterItm} />
                            </Fade>
                            <Fade right>
                                <PopularPost />
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )

}


export default Home;