import React from 'react';


const PopularPost = () => {



    return (

        <div className="sidebar-box">
{/*         <h3 className="heading">Popular Posts</h3>
        <div className="post-entry-sidebar">
            <ul>
                <li> <a href=""> <img src="images/banner-1.jpg" alt="Image placeholder" className="mr-4"/>
                    <div className="text">
                        <h4>Lorem dolor sit amet ipsum dolor sit amet</h4>
                        <div className="post-meta"> <span className="mr-2">March 25, 2023 </span> </div>
                    </div>
                </a> </li>
                <li> <a href=""> <img src="images/banner-1.jpg" alt="Image placeholder" className="mr-4"/>
                    <div className="text">
                        <h4>Lorem dolor sit amet ipsum dolor sit amet</h4>
                        <div className="post-meta"> <span className="mr-2">March 25, 2023 </span> </div>
                    </div>
                </a> </li>
                <li> <a href=""> <img src="images/banner-1.jpg" alt="Image placeholder" className="mr-4"/>
                    <div className="text">
                        <h4>Lorem dolor sit amet ipsum dolor sit amet</h4>
                        <div className="post-meta"> <span className="mr-2">March 25, 2023 </span> </div>
                    </div>
                </a> </li>
            </ul>
        </div> */}
    </div>

    )

}

export default PopularPost;