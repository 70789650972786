
const BlogReducer = (state, action) => {
    switch (action.type) {
        case "BLOGDATA":
            let count = state?.BlogData?.length
            return {
                ...state,
                BlogData:state?.BlogData,
                TotalCount: count,
                BlogLoad:true
            };
        default:
            return state;
    }
};

export default BlogReducer;