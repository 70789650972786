import React, { useState, useEffect } from 'react';
import { Col, Container, Nav, Navbar, NavDropdown, Row, p } from 'react-bootstrap';
import { Fade, Flip } from 'react-reveal';


const Header = () => {



    return (

        <Container>
            <Row className='justify-content-end'>
                <Col lg={6} sm={6} className='logo-all'>
                    <Fade left>
                        <a href="/">
                            <img src='images/home_logo.png' className="img-fluid main-logo" alt="WealthEngines.AI" />
                        </a>
                    </Fade>
                </Col>
                <Col lg={6} sm={6} className='logo-all ai-labs-logo'>
                    <Fade right>
                        <a href="https://ailabs.finance/" target="_blank">
                            <img src='images/logo_final.jpg' className="img-fluid ailabs-finance-logo" alt="AI Labs Finance" />
                        </a>
                    </Fade>
                </Col>
            </Row>
        </Container >

    )

}

export default Header;