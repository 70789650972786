const BlogType = [
    {
        id:1,
        name:"News"
    },
    {
        id:2,
        name:"Article"
    },
    {
        id:3,
        name:"Earning's Call"
    }
]

export default BlogType;