import React, { useContext } from 'react';
import { BlogContext } from '../App';

const Categories = (props) => {
    const { state, dispatch } = useContext(BlogContext);
    const { AllBlogData, TotalCounts, Article_count, Earnings_count, News_count, FilterItm,currentFilter } = props;
    const uniqueTags = [];
    AllBlogData.map(itm => {
        if (uniqueTags.indexOf(itm.Type) === -1) {
            uniqueTags.push(itm.Type)
        }
    });

    const getCount = (id) => {
        if (!AllBlogData) return
        let count = 0;
        switch (+id) {
            case 1:
                count = News_count
                break;
            case 2:
                count = Article_count
                break;
            case 3:
                count = Earnings_count
                break;
        }
        return count;
    }

    const getname = (id) => {
        let fdata = state?.BlogList?.filter((itm) => +itm.id === +id);
        return fdata[0].name
    }


    return (

        <div className="sidebar-box">
            <h3 className="heading">Categories</h3>
            <ul className="categories">
                <li key={0} onClick={() => FilterItm(0)} className={currentFilter === 0 ? 'active' : ''}>
                    All <span>({TotalCounts})
                    </span>
                </li>
                {
                    uniqueTags ? uniqueTags.map((itm, i) =>
                        <li key={i} onClick={() => FilterItm(itm)} className={currentFilter === itm ? 'active' : ''}>
                            {getname(itm)} <span>({getCount(itm)})
                            </span>
                        </li>)
                        : ''
                }
            </ul>
        </div>

    )

}

export default Categories;