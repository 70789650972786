import { useCallback, useEffect, useMemo, useState } from "react";
// import Particles from "react-particles";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const Banner = () => {
  const [init, setInit] = useState(false);
  useEffect(() => {
    initParticlesEngine(async (engine) => {

      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);


  const options = useMemo(
    () => ({
      fullScreen: {
        enable: false
      },
      fpsLimit: 400,
      interactivity: {
        detect_on: "ca",
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "attract",
            parallax: { enable: false, force: 400, smooth: 10 }
          },
          resize: true
        },
        modes: {
          push: {
            quantity: 8,
            attract: { distance: 200, duration: 0.4, factor: 8 }
          },
          repulse: {
            distance: 200,
            //duration: 0.8,
          },
        },
      },
      particles: {
        color: {
          value: "#939393",
          opacity: 0.2,
        },
        links: {
          color: "#f9f9f9",
          enable: true,
          opacity: 0.4,
          width: 1,
        },
        
        move: {
          attract: { enable: false, rotateX: 600, rotateY: 1200 },
          bounce: false,
          direction: "none",
          enable: true,
          out_mode: "out",
          random: false,
          speed: 2,
          straight: false
        },
        number: { density: { enable: true, value_area: 1800 }, value: 500 },
        opacity: {
          anim: { enable: false, opacity_min: 0.2, speed: 1, sync: false },
          random: false,
          value: 0.5
        },
        shape: {
          polygon: { nb_sides: 5 },
          type: "circle"
        },
        size: {
          anim: { enable: false, size_min: 0.1, speed: 50, sync: false },
          random: true,
          value: 3
        }
      },
      retina_detect: true
    }),
    [],
  );


  return (
    <>
      <div className="site-cover site-cover-sm same-height overlay single-page-banner">
        {
          init && <Particles
            id="tsparticles"
            options={options}
          />
        }
      </div>
    </>
  );
};

export default Banner;