import React, { useEffect, useState } from 'react';
import './App.css';
import Home from './Pages/Home';
import BlogType from './Services/BlogType';
import BlogReducer from './Services/BlogReducer';

export const BlogContext = React.createContext();
const initialState = {
  BlogData: null,
  BlogList: BlogType,
  TotalCount: null,
  Page_num:null,
  BlogLoad:false
};
function App() {
  const [state, dispatch] = React.useReducer(BlogReducer, initialState);

  return (
    <BlogContext.Provider value={{ state, dispatch }}>
      <Home />
    </BlogContext.Provider>
  );
}

export default App;
