import React from 'react';

const SiteBarSearch = (props) => {
    const {handleSearch} = props;


    return (

        <div className="sidebar-box search-form-wrap mt-3">
            <form onSubmit={handleSearch} className="search-form">
                <div className="form-group"> <span className="icon fa fa-search"></span>
                    <input type="search" className="form-control" placeholder="Keyword Search" id="search_input"/>
                    <button type="submit" className='blog-search'><img src='images/search.png' /></button>                    
                </div>
            </form>
        </div>

    )

}

export default SiteBarSearch;