import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import Splitter from '../Services/Splitter.service';
import validURL from '../Services/UrlValidator.service';
import '../styles/readmore.css'
import { Fade } from 'react-reveal';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const LiWithStrong = (props) => {
    const { data } = props;

    if (!data) return;

    let fdata = data.split(":");
    return <li>
        {
            fdata.length === 2 ?
                <>
                    <strong>{fdata[0]}:</strong>
                    {
                        validURL(fdata[1]) ?
                            <a href={fdata[1]} target='_blank'>{fdata[1]}</a> : fdata[1]
                    }
                </>
                :
                <>
                    {
                        validURL(fdata[0]) ?
                            <a href={fdata[0]} target='_blank' rel="noreferrer">{fdata[0]}</a> : fdata[0]
                    }
                </>

        }
    </li>
}

const BlogCard = (props) => {
    const { data, id } = props;
    const content = Splitter(data?.Content, '@@##@@');
    const [init, setInit] = useState(false);

    const getClassName = (id) => {
        let cls = '';
        switch (+id) {
            case 1:
                cls = 'news_bg'
                break;
            case 2:
                cls = 'article_bg'
                break;
            case 3:
                cls = 'earning_bg'
                break;
            default:
                cls = ""
                break;
        }
        return cls;
    }

    useEffect(() => {
        initParticlesEngine(async (engine) => {

            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const options = useMemo(
        () => ({
            fullScreen: {
                enable: false
            },
            particles: {
                color: {
                    value: "#c9c9c9",
                  },
                links: {
                    color: "#c9c9c9",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                },
                move: {
                    enable: true
                },
                number: {
                    value: 50
                },
                opacity: {
                    value: { min: 0.3, max: 1 }
                },
                shape: {
                    type: ["circle", "square", "triangle", "polygon"],
                    options: {
                        polygon: [
                            {
                                sides: 5
                            },
                            {
                                sides: 6
                            },
                            {
                                sides: 8
                            }
                        ]
                    }
                },
                size: {
                    value: { min: 1, max: 3 }
                }
            }
        }),
        [],
    );


    return (
        <div className={`entry2 hover-underline-aimation ${getClassName(data.Type)}`}>
            {
                    init && <Particles
                        id={`tsparticles${id}`}
                        className='blog_particles'
                        options={options}
                    />
                }
            <div className='padding-blog'>
                <Fade bottom >
                    {
                        !data?.Heading ? <h2>{`${data?.Name} ${data?.Ticker} ${data?.Year} - ${data?.Quarter}`}</h2>
                            : <h2>{data?.Heading}</h2>
                    }
                </Fade>
                <Fade bottom>

                    <div className="post-meta align-items-center text-left clearfix">
                        <span>
                            {
                                data?.Sector && <>Sector: <strong>{data?.Sector}</strong> {data?.Industry && <>| </>}</>
                            }
                            {
                                data?.Industry && <>Industry: <strong>{data?.Industry}</strong> {data?.Sentiment && <>| </>}</>
                            }
                            {
                                data?.Sentiment && <>Sentiment of the News: <strong>{data?.Sentiment}</strong></>
                            }
                        </span>
                    </div>

                    <div className='read-more'>
                        <input id={`read-more-checkbox${id}`} type="checkbox" name='read-more-checkbox' className="read-more__checkbox" aria-hidden="true" />
                        <div className='read-more__text'>
                            {
                                data.Type === "2" || data.Type === "3" ?
                                    <>
                                        <ul >
                                            {content?.map((itm, i) =>
                                                <LiWithStrong data={itm} key={i} />

                                            )}
                                        </ul>
                                    </>
                                    :
                                    <>
                                        <p>{content}</p>
                                        {/* {content?.map((itm, i) =>
                                    <p key={i}>{itm}</p>
                                )} */}
                                    </>

                            }
                            {
                                data?.Citation && <p> <strong>Citation:</strong> {data?.Citation} </p>
                            }
                            {
                                data?.Link && <p><strong>Source:</strong> <a href={data?.Link} target='_blank' rel="noreferrer">{data?.Link}</a></p>
                            }
                        </div>

                        <label htmlFor={`read-more-checkbox${id}`} className="read-more__label" data-read-more="Read more" data-read-less="See less" aria-hidden="true"></label>
                    </div>
                </Fade>
                
            </div>
        </div>



    )

}


export default BlogCard;