import React, { useContext, useEffect, useState } from 'react';
import BlogCard from './BlogCard';
import { BlogContext } from '../App';


const BlogList = (props) => {
    const { state, dispatch } = useContext(BlogContext);
    const { AllBlogData } = props;

    return (
        <>
            {
                AllBlogData.map((itm, i) => <BlogCard data={itm} key={i} id={i} />)
            }</>

    )

}


export default BlogList;